import React from "react";
import { createRoot } from "react-dom/client";
import Toast from "./toast";

type ToastOptions = {
  actionLabel?: string;
  action?: () => void;
  duration?: number;
};

export function showToast(message: string, options?: ToastOptions) {
  const duration = options?.duration ?? 4000;
  const toastRoot = document.createElement("div");
  document.body.appendChild(toastRoot);
  const root = createRoot(toastRoot);
  let removed = false;

  const closeToast = () => {
    if (removed) return;
    removed = true;
    root.unmount();
    document.body.removeChild(toastRoot);
  };

  root.render(
    <Toast
      message={message}
      action={options?.action}
      actionLabel={options?.actionLabel}
      onClose={closeToast}
    />
  );

  setTimeout(closeToast, duration);
}
