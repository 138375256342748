/* eslint-disable @next/next/no-img-element */
import "./carousel.css";
import { cn } from "common/utils";
import { LeftArrow, RightArrow } from "../../icons/arrows";
import { useState } from "react";
import { LayeredPreviewModal } from "../card/layered-preview-modal";
import { SearchIcon } from "lucide-react";

type Card = {
  id: string;
  name: string;
  url: string;
  backUrl: string;
};

type Props = {
  currentCard: number;
  cards: Card[];
  onCardChange: (index: number) => void;
};

const getNextIndexes = (
  currentIndex: number,
  length: number
): [number | undefined, number | undefined] => {
  if (length === 1) return [undefined, undefined];
  if (length === 2) return [(currentIndex + 1) % length, undefined];
  return [(currentIndex + 1) % length, (currentIndex + 2) % length];
};

export function CardCarousel({ currentCard, cards, onCardChange }: Props) {
  const [isFlippingNext, setIsFlippingNext] = useState(false);
  const [isFlippingPrevious, setIsFlippingPrevious] = useState(false);
  const [indexTwo, indexThree] = getNextIndexes(currentCard, cards.length);
  const [previewOpen, setPreviewOpen] = useState(false);
  const previousCard = currentCard > 0 ? cards[currentCard - 1] : undefined;
  const topCards = [
    cards[currentCard],
    indexTwo !== undefined ? cards[indexTwo] : indexTwo,
    indexThree !== undefined ? cards[indexThree] : undefined
  ].filter<Card>((c): c is Card => c !== undefined);

  const onNext = () => {
    setIsFlippingNext(true);
    setTimeout(() => {
      setIsFlippingNext(false);
      onCardChange(currentCard + 1);
    }, 200);
  };

  const onPrevious = () => {
    setIsFlippingPrevious(true);
    setTimeout(() => {
      setIsFlippingPrevious(false);
      onCardChange(currentCard - 1);
    }, 200);
  };

  const getPrevClass = (topCardsLength: number) => {
    return topCardsLength > 2 ? "carouselPrevTwo" : "carouselPrevThree";
  };

  return (
    <div className="flex w-[140px] flex-col justify-start gap-y-2 sm:w-[155px]">
      <div
        onClick={() => setPreviewOpen(true)}
        className="relative h-[155px] w-[110px] [perspective:500px] sm:h-[180px] sm:w-[128px]"
      >
        {topCards.map((card, idx) => (
          <div
            key={card.id}
            className={cn(
              "absolute top-0 w-full shadow-md [transform-style:preserve-3d]",
              {
                "cursor-pointer": idx === 0
              },
              idx === 0 && isFlippingNext ? "carouselNext" : undefined,
              idx === 1 && isFlippingNext ? "carouselNextTwo" : undefined,
              idx === 2 && isFlippingNext ? "carouselNextThree" : undefined,
              idx === 0 && isFlippingPrevious ? "carouselPrev" : undefined,
              idx === 1 && isFlippingPrevious ? getPrevClass(topCards.length) : undefined,
              idx === 2 && isFlippingPrevious ? "carouselPrevLast" : undefined
            )}
            style={{
              zIndex: 3 - idx,
              left: `${idx * 20}px`,
              transform: `translateZ(-${idx * 60}px)`
            }}
          >
            <img src={card.url} className={"w-full"} alt="Card preview" />
            <div
              className={cn(
                "absolute bottom-0 right-0 z-30 flex h-6 w-6 items-center justify-center rounded-md bg-white/85",
                { hidden: idx !== 0 }
              )}
            >
              <SearchIcon strokeWidth={3} width={12} height={12} />
            </div>
          </div>
        ))}
        {previousCard && isFlippingPrevious && topCards.length > 2 && (
          <img
            key={previousCard.id}
            src={previousCard.url}
            className={cn(
              "carouselPrevThree absolute top-0 w-36 shadow-md [transform-style:preserve-3d]"
            )}
            style={{
              zIndex: 0,
              left: `30px`,
              transform: `translateZ(-120px)`
            }}
            alt="Card preview"
          />
        )}
      </div>
      <div className="flex w-[120px] items-center justify-center gap-x-4 sm:w-[128px]">
        <button
          className="rounded-md bg-white/65 p-[6px] hover:bg-white/80"
          onClick={onPrevious}
          disabled={currentCard === 0 || isFlippingNext}
        >
          <LeftArrow width={14} height={14} color={currentCard === 0 ? "gray" : "black"} />
        </button>
        <p className="mt-[2px] whitespace-nowrap text-xs">{topCards[0].name}</p>
        <button
          className="rounded-md bg-white/65 p-[6px] hover:bg-white/80"
          onClick={onNext}
          disabled={currentCard === cards.length - 1 || isFlippingNext}
        >
          <RightArrow
            width={14}
            height={14}
            color={currentCard === cards.length - 1 ? "gray" : "black"}
          />
        </button>
      </div>
      <LayeredPreviewModal
        open={previewOpen}
        frontUrl={topCards[0]?.url}
        backUrl={topCards[0]?.backUrl}
        onBack={() => setPreviewOpen(false)}
      />
    </div>
  );
}
