import { VariantProps, cva } from "class-variance-authority";
import { ReactNode } from "react";
import { cn } from "common/utils";
import { LoadingIndicator } from "../loading-indicator";
import * as React from "react";

const buttonVariants = cva(
  "hover:shadow-lg relative border border-2 active:shadow-sm flex items-center justify-center font-semibold rounded-full disabled:hover:cursor-not-allowed disabled:opacity-65 disabled:shadow-none select-none",
  {
    variants: {
      variant: {
        default:
          "bg-black border-black hover:bg-gray-800 active:bg-gray-900 text-white disabled:hover:bg-black hover:bg-black",
        outline: "border-black text-black bg-transparent",
        white: "border-white text-legend-purple bg-white",
        purple: "border-legend-purple text-white bg-legend-purple",
        transparent:
          "border-transparent text-white bg-transparent hover:shadow-none hover:underline decoration-2",
        red: "border-red-600 text-white bg-red-600"
      },
      size: {
        default: "px-4 py-2 text-base",
        small: "px-4 py-1.5 text-sm"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    loading?: boolean;
    icon?: ReactNode;
  };

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ loading, children, variant, icon, size, className, ...props }: ButtonProps, ref) => {
    return (
      <button className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
        <span
          className={cn(
            "xs:gap-x-2 flex items-center justify-center gap-x-1.5",
            loading && "opacity-0"
          )}
        >
          {icon}
          {children}
        </span>
        {loading && (
          <div className="absolute flex h-full w-full items-center justify-center">
            <LoadingIndicator variant={variant === "outline" ? "black" : "white"} size="small" />
          </div>
        )}
      </button>
    );
  }
);

Button.displayName = "Button";
