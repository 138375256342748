import { create } from "zustand";

export const initialState = {
  accountDropdownOpen: false
};

export type UiState = {
  accountDropdownOpen: boolean;
  setAccountDropdownOpen: (open: boolean) => void;
  clear: () => void;
};

// Store for shared UI controls
// For example, somewhere deep in the tree might need to trigger the account dropdown to open.
export const useUiStore = create<UiState>((set) => ({
  ...initialState,
  setAccountDropdownOpen: (accountDropdownOpen) => set({ accountDropdownOpen }),
  clear: () => set(initialState)
}));
