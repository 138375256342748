import Link from "next/link";
import useAuth from "../../hooks/useAuth";
import { useRouter } from "next/router";
import { useState } from "react";
import { ClientOnly } from "legend-ui/components";
import { ShoppingCartIcon } from "lucide-react";
import AccountDropdown from "./account-dropdown";

const MyAccount = ({ onCartOpen }) => {
  return (
    <div className="absolute inset-y-0 right-0 flex flex items-center sm:pr-2">
      <button
        onClick={onCartOpen}
        type="button"
        className="rounded-md px-2 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white md:px-3"
      >
        <ShoppingCartIcon />
      </button>
      <AccountDropdown />
    </div>
  );
};

const SignInButton = ({ onSignIn, onCartOpen }) => {
  return (
    <div className="absolute inset-y-0 right-0 flex flex items-center sm:pr-2">
      <button
        onClick={() => onSignIn()}
        className="hidden rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white md:block"
      >
        Log in
      </button>
      <button
        onClick={onCartOpen}
        type="button"
        className="rounded-md px-2 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white md:px-3"
      >
        <ShoppingCartIcon />
      </button>
    </div>
  );
};

const NavLink = ({ href, children }) => (
  <Link
    href={href}
    className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
    aria-current="page"
  >
    {children}
  </Link>
);

const MobileNavLink = ({ href, children }) => (
  <Link
    href={href}
    className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
  >
    {children}
  </Link>
);

type Props = {
  teamFlowId?: string;
  onCartOpen?: () => void;
};

const NavBar = ({ teamFlowId, onCartOpen }: Props) => {
  const { session } = useAuth();
  const router = useRouter();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const defaultCartOpen = () => router.push("/review");

  const getCreateCardUrl = () =>
    teamFlowId ? `/create?teamId=${teamFlowId}&flow=team` : "/create";

  return (
    <ClientOnly>
      <nav className="bg-black md:mx-4 md:mt-4">
        <div className="max-w-8xl mx-auto px-2 md:px-6 lg:px-8">
          <div className="relative flex h-14 items-center justify-between md:h-16">
            <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
              {/* Mobile menu button */}
              <button
                type="button"
                className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => {
                  setMobileMenuOpen((isOpen) => !isOpen);
                }}
              >
                <span className="absolute -inset-0.5"></span>
                <span className="sr-only">Open main menu</span>
                {/* Icon when menu is closed. Menu open: "hidden", Menu closed: "block" */}
                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
                {/* Icon when menu is open. Menu open: "block", Menu closed: "hidden" */}
                <svg
                  className="hidden h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="flex flex-1 items-center justify-center">
              <Link
                href="https://www.statlegend.com/"
                className="inset-y-0 left-0 flex flex-shrink-0 items-center md:absolute"
              >
                <img className="h-6" src="/assets/statlegend-logo-purple.png" alt="Stat Legend" />
              </Link>
              <div className="hidden md:ml-6 md:block">
                <div className="ml-2 flex space-x-4">
                  <NavLink href="https://www.statlegend.com/about">About</NavLink>
                  <NavLink href="https://www.statlegend.com/how-it-works">How it works</NavLink>
                  <NavLink href={getCreateCardUrl()}>Create a card</NavLink>
                  <NavLink href="https://www.statlegend.com/pricing">Pricing</NavLink>
                </div>
              </div>
            </div>
            {session && session?.role !== "guest" ? (
              <MyAccount onCartOpen={onCartOpen ?? defaultCartOpen} />
            ) : (
              <SignInButton
                onSignIn={() => router.push("/login")}
                onCartOpen={onCartOpen ?? defaultCartOpen}
              />
            )}
          </div>
        </div>

        {/* Mobile menu, show/hide based on menu state. */}
        {mobileMenuOpen && (
          <div className="md:hidden" id="mobile-menu">
            <div className="space-y-1 px-2 pb-3 pt-2">
              <MobileNavLink href="https://www.statlegend.com/about">About</MobileNavLink>
              <MobileNavLink href="https://www.statlegend.com/how-it-works">
                How it works
              </MobileNavLink>
              <MobileNavLink href="https://www.statlegend.com/pricing">Pricing</MobileNavLink>
              <Link
                href={getCreateCardUrl()}
                onClick={() => setMobileMenuOpen(false)}
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                aria-current="page"
              >
                Create a card
              </Link>
              {!session ||
                (session?.role === "guest" && <MobileNavLink href="/login">Log in</MobileNavLink>)}
            </div>
          </div>
        )}
      </nav>
    </ClientOnly>
  );
};

export default NavBar;
