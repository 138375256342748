import { Root, List, Trigger, Content } from "@radix-ui/react-tabs";
import { cn } from "common/utils";
import { ComponentProps } from "react";
import styles from "./tab.module.css";

export const Tabs = ({ children, className, ...props }: ComponentProps<typeof Root>) => (
  <Root {...props} className={cn(className)}>
    {children}
  </Root>
);

export const TabList = ({ children, className, ...props }: ComponentProps<typeof List>) => (
  <List
    {...props}
    className={cn(styles["TabsList"], "border-legend-purple flex shrink-0 border-b", className)}
  >
    {children}
  </List>
);

export const TabTrigger = ({ children, className, ...props }: ComponentProps<typeof Trigger>) => (
  <Trigger
    {...props}
    className={cn(
      styles["TabsTrigger"],
      "text-legend-purple h-[45px] flex-1 select-none items-center justify-center bg-white px-4 py-0 text-base leading-none hover:bg-gray-50",
      className
    )}
  >
    {children}
  </Trigger>
);

export const TabContent = ({ children, className, ...props }: ComponentProps<typeof Content>) => (
  <Content {...props} className={cn(className)}>
    {children}
  </Content>
);
