import styles from "./loading-indicator.module.css";

interface Props {
  variant?: "white" | "black" | "purple";
  size?: "small" | "regular";
}

export function LoadingIndicator({ variant = "white", size = "regular" }: Props) {
  const classes = [styles["loading-indicator"]];

  if (variant === "white") {
    classes.push(styles["white"]);
  }
  if (variant === "black") {
    classes.push(styles["black"]);
  }
  if (variant === "purple") {
    classes.push(styles["purple"]);
  }

  if (size === "small") {
    classes.push(styles["sm"]);
  }

  return (
    <div className={classes.join(" ")}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
