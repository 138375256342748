import { forwardRef } from "react";
import { cn } from "common/utils";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  labelClassName?: string;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, labelClassName, className, ...props }: InputProps, ref) => {
    const withLabel = (input: JSX.Element) => {
      if (!label) return input;
      return (
        <div className="flex flex-col">
          <label htmlFor={props.id} className={cn("inline-block pb-2", labelClassName)}>
            {label}
          </label>
          {input}
        </div>
      );
    };

    return withLabel(
      <input className={cn("rounded-full p-2 px-4", className)} ref={ref} {...props} />
    );
  }
);

Input.displayName = "Input";
