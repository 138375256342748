import { forwardRef } from "react";
import { cn } from "common/utils";

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  labelClassName?: string;
  containerClassName?: string;
  height?: number;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { label, labelClassName, containerClassName, height = 80, className, ...props }: TextAreaProps,
    ref
  ) => {
    const withLabel = (input: JSX.Element) => {
      if (!label) return input;
      return (
        <div className="flex flex-col">
          <label htmlFor={props.id} className={cn("inline-block pb-2", labelClassName)}>
            {label}
          </label>
          {input}
        </div>
      );
    };

    return withLabel(
      <div
        className={cn("overflow-hidden rounded-xl", containerClassName)}
        style={{
          height: `${height}px`
        }}
      >
        <textarea
          ref={ref}
          className={cn(`h-full w-full resize-none rounded-xl px-3 py-2`, className)}
          {...props}
        />
      </div>
    );
  }
);

TextArea.displayName = "TextArea";
