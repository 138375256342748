import { forwardRef } from "react";
import { cn } from "common/utils";

export type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  defaultSelected: boolean;
  placeholder: string;
  label?: string;
  labelClassName?: string;
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      defaultSelected,
      defaultValue,
      label,
      labelClassName,
      placeholder,
      children,
      ...props
    }: SelectProps,
    ref
  ) => {
    const withLabel = (input: JSX.Element) => {
      if (!label) return input;
      return (
        <div className="flex flex-col">
          <label htmlFor={props.id} className={cn("inline-block pb-2", labelClassName)}>
            {label}
          </label>
          {input}
        </div>
      );
    };

    return withLabel(
      <div className="relative">
        <select
          ref={ref}
          className={cn(
            "w-full cursor-pointer appearance-none rounded-full border border-gray-300 bg-white px-4 py-2 focus:bg-white",
            {
              "text-gray-400": defaultSelected
            },
            className
          )}
          defaultValue={defaultValue ?? ""}
          {...props}
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {children}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
          <svg
            className="h-4 w-4 text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
    );
  }
);

Select.displayName = "Select";
