import { XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";

interface ToastProps {
  message: string;
  action?: () => void;
  actionLabel?: string;
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, onClose, action, actionLabel }) => {
  return (
    <div className="fixed bottom-4 left-0 right-0 z-50 flex w-full justify-center px-4">
      <div className="flex max-w-lg items-center rounded-md bg-slate-800 p-4 shadow-md">
        <div>
          <p className="text-sm text-white">{message}</p>
          {action && (
            <p
              className="inline cursor-pointer text-sm text-blue-400 hover:text-blue-300"
              onClick={() => {
                action();
                onClose();
              }}
            >
              {actionLabel ?? "View"}
            </p>
          )}
        </div>
        <div className="pl-2">
          <XMarkIcon
            width={22}
            height={22}
            color="#FFF"
            onClick={onClose}
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default Toast;
