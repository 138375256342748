import { ReactNode, useState } from "react";
import { FlipHorizontal } from "lucide-react";
import { Button, ButtonProps } from "../button";
import { LoadingIndicator } from "../loading-indicator";
import { cn } from "common/utils";
import { IconButton, IconButtonProps } from "../icon-button";

type CardImageProps = {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  className?: string;
};

type FlippableCardProps = {
  front: CardImageProps;
  back: CardImageProps;
  extraControl?: ReactNode;
  defaultFlipped?: boolean;
  loading?: boolean;
  hideControls?: boolean;
  buttonVariant?: ButtonProps["variant"] | IconButtonProps["variant"];
  loadingMessage?: string;
};

export const FlippablePreview = ({
  front,
  back,
  buttonVariant,
  loadingMessage,
  extraControl,
  defaultFlipped = false,
  loading = false,
  hideControls = false
}: FlippableCardProps) => {
  const [flipped, setFlipped] = useState(defaultFlipped);
  const flipText = `Flip to ${flipped ? "front" : "back"}`;

  const renderLoader = () => (
    <div className="absolute left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center gap-y-4 bg-black/35">
      <LoadingIndicator />
      {loadingMessage && (
        <p className="max-w-48 text-center text-sm text-white">{loadingMessage}</p>
      )}
    </div>
  );

  return (
    <div className="flex flex-col items-center">
      <div className="group relative h-[380px] w-[268px] select-none [perspective:1000px] lg:h-[430px] lg:w-[303px]">
        <div
          className={cn([
            "relative h-full w-full transition-all duration-500 [transform-style:preserve-3d]",
            flipped && "[transform:rotateY(180deg)]"
          ])}
        >
          <div className="absolute left-0 top-0">
            <img src={front.src} alt={front.alt} className={cn(front.className, "shadow-xl")} />
            {!flipped && loading && renderLoader()}
          </div>

          <div className="absolute left-0 top-0 w-full text-center text-slate-200 [backface-visibility:hidden] [transform:rotateY(180deg)]">
            <img src={back.src} alt={back.alt} className={cn(back.className, "shadow-xl")} />
            {flipped && loading && renderLoader()}
          </div>
        </div>
      </div>
      {!hideControls && (
        <div className={"flex gap-x-5 pt-4"}>
          {extraControl ? (
            <IconButton
              icon={<FlipHorizontal size="20" />}
              label="Flip"
              disabled={loading}
              variant={buttonVariant}
              onClick={() => setFlipped((state) => !state)}
            />
          ) : (
            <Button
              icon={<FlipHorizontal size="20" />}
              disabled={loading}
              variant={buttonVariant}
              onClick={() => setFlipped((state) => !state)}
            >
              {flipText}
            </Button>
          )}
          {extraControl && extraControl}
        </div>
      )}
    </div>
  );
};
