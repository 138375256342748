import { create } from "zustand";
import { persist } from "zustand/middleware";
import { CheckoutFormValues } from "./checkout";
import { AttributesStep, BlurbStep, CardStepData, PhotoStep } from "./card";

export const initialState = {
  cardStepData: {},
  checkout: undefined,
  workspaceId: undefined
};

export type OrderFormState = {
  workspaceId?: string;
  cardStepData: CardStepData;
  checkout?: CheckoutFormValues;
  setCheckout: (checkout: CheckoutFormValues) => void;
  setWorkspaceId: (workspaceId: string) => void;
  setCardStep: (data: Partial<CardStepData>) => void;
  setPhotoStep: (data: Partial<PhotoStep>) => void;
  setBlurbStep: (data: Partial<BlurbStep>) => void;
  setAttributesStep: (data: Partial<AttributesStep>) => void;
  clear: () => void;
};

export const useOrderFormStore = create<OrderFormState>()(
  persist(
    (set) => ({
      ...initialState,
      setWorkspaceId: (workspaceId: string) => set({ workspaceId }),
      setCheckout: (checkout: CheckoutFormValues) => set({ checkout }),
      setCardStep: (data: Partial<CardStepData>) =>
        set((state) => ({
          cardStepData: {
            ...state.cardStepData,
            ...data
          }
        })),
      setPhotoStep: (data: Partial<PhotoStep>) =>
        set((state) => ({
          cardStepData: {
            ...state.cardStepData,
            photoStep: {
              ...state.cardStepData?.photoStep,
              ...data
            }
          }
        })),
      setAttributesStep: (data: Partial<AttributesStep>) =>
        set((state) => ({
          cardStepData: {
            ...state.cardStepData,
            attributesStep: {
              ...state.cardStepData?.attributesStep,
              ...data
            }
          }
        })),
      setBlurbStep: (data: Partial<BlurbStep>) =>
        set((state) => ({
          cardStepData: {
            ...state.cardStepData,
            blurbStep: {
              ...state.cardStepData?.blurbStep,
              ...data
            }
          }
        })),
      clear: () => set(initialState)
    }),
    {
      name: "order-form-store-persist"
    }
  )
);
