import React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptySubscribe = () => () => {};

export const ClientOnly = ({ children }) => {
  const value = React.useSyncExternalStore(
    emptySubscribe,
    () => "client",
    () => "server"
  );

  return value === "server" ? null : <>{children}</>;
};
