import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const classNames = (...classes: (string | boolean)[]) => {
  return classes.filter(Boolean).join(" ");
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
