import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const classNames = (...classes: (string | boolean)[]) => {
  return classes.filter(Boolean).join(" ");
};

export const getTimestamp = () => Math.floor(Date.now() / 1000);

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type Milliseconds = number;

export const wait = (ms: Milliseconds) => new Promise<void>((resolve) => setTimeout(resolve, ms));
