import { Dialog, DialogTitle } from "@radix-ui/react-dialog";
import { DialogContent, DialogHeader } from "../ui/dialog";
import { LoadingIndicator } from "legend-ui";

type Props = {
  name: string;
  open: boolean;
};

export default function SwitchingWorkspaceModal({ name, open }: Props) {
  return (
    <Dialog open={open}>
      <DialogContent
        className="flex flex-col items-center justify-center !rounded-3xl !border-none bg-[#914dec] pb-8 pt-6 text-white"
        hideClose={true}
      >
        <DialogHeader>
          <DialogTitle className="text-center text-lg font-semibold">
            Switching to {name} workspace
          </DialogTitle>
          <div className="flex flex-col items-center justify-center gap-y-2 pt-3">
            <LoadingIndicator variant="white" />
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
