import { cn } from "common/utils";

export function Title({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p
      className={cn("xs:text-3xl text-2xl font-bold uppercase md:text-4xl lg:text-5xl", className)}
      {...props}
    >
      {children}
    </p>
  );
}
