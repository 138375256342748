import { Button } from "../button";
import { DialogHeader, Dialog, DialogContent, DialogTitle } from "../dialog";
import { FlippablePreview } from "./flippable-preview";

type Props = {
  frontUrl: string;
  backUrl: string;
  onBack: () => void;
  open: boolean;
};

export function LayeredPreviewModal({ open, onBack, frontUrl, backUrl }: Props) {
  return (
    <Dialog open={open}>
      <DialogContent className="bg-legend-purple max-h-[620px] md:max-h-[696px]" hideClose={true}>
        <DialogHeader>
          <DialogTitle className="text-center text-xl text-white sm:text-3xl">Preview</DialogTitle>
        </DialogHeader>
        <div className="flex flex-grow flex-col gap-y-6 md:gap-y-8">
          <div className="pt-8">
            <FlippablePreview
              front={{
                alt: "Card preview front",
                src: frontUrl
              }}
              back={{
                alt: "Card preview back",
                src: backUrl
              }}
              buttonVariant="white"
            />
          </div>
          <div className="flex flex-grow flex-col justify-end">
            <div className="flex flex-row pt-2">
              <Button variant="white" className="w-full" onClick={onBack}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
