import { Dialog, DialogTitle } from "@radix-ui/react-dialog";
import { DialogContent, DialogDescription, DialogHeader } from "../dialog";
import { Button } from "../button";

type Props = {
  title: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
  actionText?: string;
  cancelText?: string;
  open: boolean;
};

export function ConfirmModal({
  title,
  description,
  onConfirm,
  onCancel,
  open,
  actionText = "Confirm",
  cancelText = "Cancel"
}: Props) {
  return (
    <Dialog open={open}>
      <DialogContent
        className="flex min-h-[240px] w-[400px] flex-col items-center justify-center !rounded-3xl !border-none bg-[#914dec] text-white"
        hideClose={true}
      >
        <DialogHeader>
          <DialogTitle className="py-2 text-center text-lg font-semibold">{title}</DialogTitle>
          {description && <DialogDescription>{description}</DialogDescription>}
          <div className="flex flex-col items-center justify-center gap-y-2 pt-3">
            <Button onClick={onConfirm} variant="white" className="w-60">
              {actionText}
            </Button>
            <Button onClick={onCancel} variant="transparent" className="w-60">
              {cancelText}
            </Button>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
